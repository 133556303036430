import React, { useEffect, useRef, useState, useCallback } from 'react';

import Map, { Source, Layer, AttributionControl } from 'react-map-gl/maplibre';
import maplibregl from '!maplibre-gl';
import { useDispatch, useSelector } from 'react-redux';
import bbox from '@turf/bbox';
import center from '@turf/center';
import buffer from '@turf/buffer';

import 'maplibre-gl/dist/maplibre-gl.css';
import { config } from '../config';
import { openInfoPoi, setBoundsPoi, setIdViewImage, setLabelPoi, setLoadingImage, setTypePoiSelected } from '../slices/poiSlice';
import { closeSearch } from '../slices/searchSlice';
import { openSurvey, setSurvey, setSurveyId, setTypeSurvey, setWaysToSurvey } from '../slices/surveySlice';
import { resetPointStreet, setAddStreet, setInteractMap, stopResetSelected } from '../slices/mapSlice';
import { setSelectedFromEvalGeoJson, setSelectedGeoJson } from '../hooks/useGeoJson';
import { MapEvaluatedLayers } from './MapEvaluatedLayers';
import { callGetWayJsonByCoord, callGetWayJsonByName } from '../api/FeatureApi';
import { useResponsive } from '../hooks/useResponsive';
import { closeNoti, openNoti, setContentNoti, setTypeNoti } from '../slices/notiSlice';
import { getImageStreetId } from '../api/streetviewApi';

const layerStyle = {
  id: 'edges',
  type: 'line',
  paint: {
    'line-width': [
      'interpolate',
      ['linear'],
      ['zoom'],
      15, 10,
      17, 25,
      18, 35,
      19, 45,
      20, 100,
      22, 130
    ],
    'line-opacity': 0
  }
};

export const MapApp = () => {

  const mapRef = useRef();
  const { isMobile, isDesktop } = useResponsive();
  const dispatch = useDispatch();
  const limitBounding = useSelector(state => state.map.limitBounding);
  const centerCity = useSelector(state => state.map.center);
  const layers = useSelector(state => state.map.layers);
  const showSearch = useSelector(state => state.search.open);
  const showPoi = useSelector(state => state.poi.open);
  const resetSelectedPoi = useSelector(state => state.map.resetSelected);
  const listSurveyEvaluated = useSelector(state => state.user.surveyAnswers);
  const addStreet = useSelector(state => state.map.addStreet);
  const labelSelected = useSelector(state => state.poi.labelPoi);
  const addStreetByPoint = useSelector(state => state.map.streetToPoint);
  const streetByPoint = useSelector(state => state.map.pointStreet);
  const tileUrl = useSelector(state => state.map.tileservers);
  const tileLayer = useSelector(state => state.map.layers);
  const primaryColor = useSelector(state => state.init.primary_color);
  const layerPolygoCity = useSelector(state => state.map.polygons);
  const notiShow = useSelector(state => state.noti.open);
  const interactOnZoom = useSelector(state => state.map.interact);

  const [baseMaps] = useState(`https://api.maptiler.com/maps/streets-v2/style.json?key=${config.token.MAPTILER_KEY}`);
  
  const [selectedEdges, setSelectedEdges] = useState({});
  const [selectedEdgesGeoJSON, setSelectedEdgesGeoJSON] = useState({});
  const [cursor, setCursor] = useState('auto');
  const [evaluatedEdgesGeoJson, setEvaluatedEdgesGeoJson] = useState({}); 

  const [clickEnabled, setClickEnabled] = useState(true);

  const onMouseEnter = useCallback(() => setCursor('pointer'), []);
  const onMouseLeave = useCallback(() => setCursor('auto'), []);

  const onEnableClick = useCallback(() => setClickEnabled(true), []);
  const onDisableClick = useCallback(() => setClickEnabled(false), []);


  const polygonCityStyle = {
    type: 'fill',
    paint: {
      'fill-color': '#A9A9A9',
      'fill-opacity': 0.5
    }
  };

  const selectedEdgesStyle = {
    type: 'line',
    layout: {
      'line-cap': 'round'
    },
    paint: {
      'line-width': 10,
      'line-color': `${primaryColor}`
    }
  };

  const handleInteractOnZoomLevel = () => {
    if (mapRef.current && mapRef.current.getZoom() > 15) {
      dispatch(setInteractMap(true));
    } else {
      dispatch(setInteractMap(false));
    }
  };

  const handleSelectedFeature = (features) => {
    if (showSearch) {
      dispatch(closeSearch());
    }
    dispatch(setLabelPoi(features.properties.name));
    dispatch(setTypePoiSelected('portion'));
  };

  const handleEvaluatedFeature = (features) => {
    if (showSearch) {
      dispatch(closeSearch());
    }
    dispatch(setLabelPoi(features.properties.name));
    dispatch(setTypePoiSelected('portion'));
  };

  const handleModifySurvey = (features) => {
    dispatch(setLabelPoi(features.properties.name));
    const surveySelected = listSurveyEvaluated.find(elem => elem.id === features.properties.survey);
    dispatch(setSurveyId(surveySelected.id));
    dispatch(setSurvey(surveySelected));
    dispatch(setTypeSurvey('update'));
    handleSelectedFeature(features);
    dispatch(openSurvey());
  };

  const handleShowNoti = (type, notification) => {
    dispatch(setContentNoti(notification));
    dispatch(setTypeNoti(type));
    dispatch(openNoti());
  };

  const handleMouseUp = async (e) => {
    e.preventDefault();
    if (notiShow) {
      dispatch(closeNoti());
    }
    if (interactOnZoom) {
      const features = mapRef.current?.getMap().queryRenderedFeatures(e.point, {
        layers: listSurveyEvaluated.length > 0 ? ['edges','evaluated-edges', 'polygon_city'] : ['edges', 'polygon_city']
      });
      if (features && features.length > 0) { 
        if (features[0].id) {
          if (features[0].layer.id === 'evaluated-edges') {
            handleModifySurvey(features[0]);
            handleEvaluatedFeature(features[0]);
          }
          if (features[0].layer.id === 'edges') {
            const newSelectedEdges = {};
            const newSelectedEdgesFeatures = await setSelectedGeoJson(features[0].id);
            newSelectedEdges[features[0].id] = newSelectedEdgesFeatures;
            setSelectedEdges(newSelectedEdges);
            dispatch(setWaysToSurvey([features[0].id]));
            handleSelectedFeature(features[0]);
            dispatch(openInfoPoi());
          }
        } else {
          if (features[0].layer.id === 'polygon_city') {
            dispatch(setContentNoti('Veuillez sélectionner une rue dans la zone couverte par le sondage.'));
            dispatch(setTypeNoti('warning'));
            dispatch(openNoti());
          }
        }
      } else {
        handleShowNoti('warning', 'Cliquez/Appuyez au centre de la rue pour pouvoir la sélectionner.');
      }
    } else {
      handleShowNoti('error', 'Veuillez zoomer pour pouvoir sélectionner une rue.');
    }
   
  };

  const handleGetStreetByName = (nameStreet) => {
    if (nameStreet) {
      callGetWayJsonByName(nameStreet).then(response => {
        const newSelectedEdges = {...selectedEdges};
        const newSelectedEdgesId = [];
        if( response !== undefined && response.length > 0 ) {
          response.forEach(feature => {
            newSelectedEdgesId.push(feature.id);
            newSelectedEdges[feature.id] = {
              type: 'Feature',
              properties: {
                fid: feature.id,
                osmid: feature.osmid,
                name: feature.name,
              },
              geometry: JSON.parse(feature.geom),
            }; 
          });
          dispatch(setWaysToSurvey(newSelectedEdgesId));
          setSelectedEdges(newSelectedEdges);
        } 
      });
    }
  };

  useEffect(() => {
    if (mapRef.current && mapRef.current !== null) {
      // disable map rotation using right click + drag
      mapRef.current.getMap().dragRotate.disable();

      // disable map rotation using touch rotation gesture
      mapRef.current.getMap().touchZoomRotate.disableRotation();
    }
  }, [mapRef.current]);

  useEffect(() => {
    const fetchIdImageView = async (boundsSearchView) => {
      const resultsGetImage = await getImageStreetId(boundsSearchView);
      if (resultsGetImage && resultsGetImage.data?.length > 0) {
        dispatch(setIdViewImage(resultsGetImage.data[0].id));
      } else {
        dispatch(setIdViewImage(null));
      }
      dispatch(setLoadingImage(false));

    };
    if (Object.keys(selectedEdges).length > 0) {
      const selectedEdgesGeoJson = {
        type: 'FeatureCollection',
        features: Object.values(selectedEdges)
      };
      setSelectedEdgesGeoJSON(selectedEdgesGeoJson);
      const bounds = bbox(selectedEdgesGeoJson);
      const centerBounds = center(selectedEdgesGeoJson);
      if(bounds !== undefined) {
        dispatch(setBoundsPoi(centerBounds));
        const buffered = buffer(centerBounds, 10, {units: 'meters'});
        const boundsSearchView = bbox(buffered);
        fetchIdImageView(boundsSearchView).catch(error => {
          console.log('error loading view', error);
          dispatch(setIdViewImage(null));
        });
        mapRef.current?.fitBounds(bounds, {
          maxZoom: isDesktop ? 18 : 16,
          padding: {
            top: isDesktop ? 20 : 0,
            left: 20,
            right: 20,
            bottom: isDesktop ? 20 : 550
          }
        });
      }
    } 
  }, [selectedEdges]);

  useEffect(() => {
    if(resetSelectedPoi) {
      setSelectedEdges({});
      setSelectedEdgesGeoJSON({});
      dispatch(stopResetSelected());
    }
  }, [resetSelectedPoi]);

  useEffect(() => {
    if (listSurveyEvaluated.length > 0) {
      const surveyEvaluatedWithWays = listSurveyEvaluated.map(elem => {
        return {id: elem.id, ways: elem.ways};
      });
      let evaluatedEdgesList = [];
      surveyEvaluatedWithWays.forEach(survey => {
        survey.ways.forEach(elem => {
          setSelectedFromEvalGeoJson(survey.id, elem).then(evalEdges => {
            evaluatedEdgesList.push(evalEdges);
          });
        });
      });
      setEvaluatedEdgesGeoJson(
        {
          type: 'FeatureCollection',
          crs: {
            'type': 'name',
            'properties': {
              'name': 'urn:ogc:def:crs:OGC:1.3:CRS84'
            }
          },
          features: evaluatedEdgesList
        }
      );
    } else {
      return;
    }
  }, [listSurveyEvaluated]);

  useEffect(() => {
    if (addStreet) {
      if (labelSelected) {
        handleGetStreetByName(labelSelected);
        dispatch(setAddStreet(false));
        setTimeout(() => {
          dispatch(openSurvey());
        }, 1000);
      } else {
        dispatch(setAddStreet(false));
        dispatch(openSurvey());
      }
    }
  }, [addStreet]);

  useEffect(() => {
    if (addStreetByPoint && streetByPoint.length > 0 && mapRef.current !== null) {
      callGetWayJsonByCoord(streetByPoint[0], streetByPoint[1]).then(
        response => {
          const nameStreet = response.name;
          if (nameStreet !== null &&  nameStreet!== '') {
            dispatch(setLabelPoi(nameStreet));
            handleGetStreetByName(nameStreet);
          } else {
            const idWays = response.id;
            const newSelectedEdges = {};
            const newSelectedEdgesId = [];
            const feature = {
              type: 'Feature',
              properties: {
                fid: response.id,
                osmid: response.osmid,
                name: response.name,
              },
              geometry: JSON.parse(response.geom),
            };
            newSelectedEdges[idWays] = feature;
            newSelectedEdgesId.push(idWays);
            setSelectedEdges(newSelectedEdges);
            dispatch(setWaysToSurvey(newSelectedEdgesId));
          }
        }
      );
      dispatch(resetPointStreet());
    }
  }, [addStreetByPoint]);

  return (
    <Map 
      id='map'
      style={{height: '100%'}}
      ref={mapRef}
      mapLib={maplibregl}
      mapStyle={baseMaps}
      attributionControl={false}
      interactiveLayerIds={['edges','evaluated-edges']}
      initialViewState={{
        longitude: centerCity && centerCity[0],
        latitude: centerCity && centerCity[1],
        zoom: 10
      }}
      onMoveStart={onDisableClick}
      onMoveEnd={onEnableClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseUp={clickEnabled && (showSearch || showPoi) && handleMouseUp}
      onZoom={handleInteractOnZoomLevel}
      cursor={cursor}
      maxBounds={limitBounding}
      styleDiffing
    >
      <AttributionControl 
        position={isMobile ? 'top-right' : 'bottom-right'}
        compact={false}
      />
      {tileUrl && tileLayer !== null && (
        <Source id='edges' type='vector' tiles={[`${tileUrl}/{z}/{x}/{y}.pbf`]} >
          <Layer id='edges' source-layer={layers} {...layerStyle} />
        </Source>
      )}
      {(listSurveyEvaluated && evaluatedEdgesGeoJson.features?.length > 0 )&& (
        <MapEvaluatedLayers data={evaluatedEdgesGeoJson}  />
      )}
      {layerPolygoCity && layerPolygoCity !== null && (
        <Source id='polygon_city' type='geojson' data={layerPolygoCity}>
          <Layer id='polygon_city' {...polygonCityStyle} />
        </Source>
      )}
      { Object.keys(selectedEdges).length > 0 &&
       (
         <Source id="selectedEdges" type="geojson" data={selectedEdgesGeoJSON}>
           <Layer {...selectedEdgesStyle} />
         </Source>
       )
      }
    </Map>
  );
};
