/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';

import { FormInfoUser } from './FormInfo/FormInfoUser';
import { listOptionHandicap } from '../../data/listOptionHandicap';
import { updateUser } from '../../api/userApi';
import { closeSurvey, resetSurvey } from '../../slices/surveySlice';
import { openSummary } from '../../slices/summarySlice';
import { startResetSelected } from '../../slices/mapSlice';
import { setRefetch } from '../../slices/initSlice';
import { UserFormFooter } from './UserFormFooter';
import { setModify } from '../../slices/userSlice';
import { openSearch } from '../../slices/searchSlice';
import { openModal, setReturnTo } from '../../slices/menuSlice';
import { useResponsive } from '../../hooks/useResponsive';

export const FormSurveyUserInfoContainer = ({handleOnClickBackward}) => {

  const {isDesktop} = useResponsive();

  const userHandicap = useSelector(state => state.user.handicap);
  const userEmail = useSelector(state => state.user.email);
  const userAge = useSelector(state => state.user.age);
  const userGender = useSelector(state => state.user.gender);
  const userId = useSelector(state => state.user.uuid);
  const userConsent = useSelector(state => state.user.consent);
  const emailConsent = useSelector(state => state.user.consent_email);
  const ageConsent = useSelector(state => state.user.consent_age);
  const handicapConsent = useSelector(state => state.user.consent_handicap);
  const modifyOnlyUser = useSelector(state => state.user.modify);


  const {
    register, 
    unregister,
    handleSubmit,
    setValue, 
    getValues, 
    control, 
    watch,
    formState: {errors}
  } = useForm({defaultValues: 
    { 
      gender: userGender, 
      age: userAge, 
      handicap: userHandicap, 
      email: userEmail,
      consent: userConsent,
      email_consent: emailConsent,
      age_consent: ageConsent,
      handicap_consent: handicapConsent
    }});
  const dispatch = useDispatch();

  const watchGenderField = watch('gender');
  const watchAgeField = watch('age');
  const watchHandicapField = watch('handicap');
  const watchEmailField = watch('email');
  const watchUserConsent = watch('consent');
  const watchAgeConsent = watch('age_consent');
  const watchEmailConsent = watch('email_consent');

  const [showConsent, setShowConsent] = useState(false);
  const [showAgeConsent, setShowAgeConsent] = useState(false);

  const handleFinishSubmit = () => {
    if (modifyOnlyUser) {
      dispatch(closeSurvey());
      dispatch(resetSurvey());
      dispatch(setRefetch(true));
      dispatch(setModify(false));
      dispatch(openSearch());
    } else {
      dispatch(closeSurvey());
      dispatch(resetSurvey());
      dispatch(setRefetch(true));
      dispatch(startResetSelected());
      dispatch(openSummary());
    }
  };

  const onSubmit = (data) => {
    /** Quand l'utilisateur coche le consentement d'utilisation des données */
    if (watchUserConsent) {
      /** Quand l'utilisateur a de 15 ans ou ne mettre pas d'age */
      if (watchAgeField >= 15 || watchAgeField === null || isNaN(watchAgeField)) {
        /** Quand l'utilisateur coche le consentement d'utilisation de l'adresse email */
        if (watchEmailConsent) {
          updateUser(userId, data).then(() => {
            handleFinishSubmit();
          }).catch(error => {
            console.log('error update with user consent / age  > 15 or null / email consent', error);
          });
        } else {
          /** Quand l'utilisateur ne coche pas le consentement d'utilisation de l'adresse email */
          updateUser(userId, {...data, email: null}).then(() => {
            handleFinishSubmit();
          }).catch(error => {
            console.log('error update with user consent / age  > 15 or null / without email consent', error);
          });
        }
      } else {
        /** Quand l'utilisateur a moins de 15 ans ( mineur ) */
        if (watchAgeConsent) {
          /** Quand ses parents cochent le consentement d'utilisation des données */
          if (watchEmailConsent) {
            /** Quand l'utilisateur coche le consentement d'utilisation de l'adresse email */
            updateUser(userId, data).then(() => {
              handleFinishSubmit();
            }).catch(error => {
              console.log('error update with user consent / age  < 15 / email consent', error);
            });
          } else {
            /** Quand l'utilisateur ne coche pas le consentement d'utilisation de l'adresse email */
            updateUser(userId, {...data, email: null}).then(() => {
              handleFinishSubmit();
            }).catch(error => {
              console.log('error update with user consent / age  < 15 / without email consent', error);
            });
          }
        } else {
          /** Quand ses parents ne cochent pas le consentement d'utilisation des données */
          updateUser(userId, {
            age: null, 
            gender: null, 
            handicap: null, 
            email: null, 
            consent: false, 
            handicap_consent: false, 
            email_consent: false, 
            age_consent: false
          }).then(() => {
            handleFinishSubmit();
          }).catch(error => {
            console.log('error update with user consent / age  > 15 or null / email consent', error);
          });
        }
      }
    } else {
      /** Quand l'utilisateur ne coche pas le consentement d'utilisation des données */
      if (watchEmailConsent) {
        /** Quand l'utilisateur coche le consentement d'utilisation de l'adresse email */
        updateUser(userId, {
          ...data,
          age: null, 
          gender: null, 
          handicap: null,  
          consent: false, 
          handicap_consent: false, 
          age_consent: false
        }).then(() => {
          handleFinishSubmit();
        }).catch(error => {
          console.log('error update with user without consent / with email consent', error);
        });
      } else {
        /** Quand l'utilisateur ne coche pas le consentement d'utilisation de l'adresse email */
        updateUser(userId, {
          age: null, 
          gender: null, 
          handicap: null,  
          email: null,
          consent: false, 
          email_consent: false,
          handicap_consent: false, 
          age_consent: false
        }).then(() => {
          handleFinishSubmit();
        }).catch(error => {
          console.log('error update with user without consent / email consent', error);
        });
      }
    }
  };

  const handleViewData = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isDesktop) {
      dispatch(closeSurvey());
    }
    dispatch(setReturnTo('survey'));
    dispatch(openModal('condition'));
  };

  useEffect(() => {
    if (watchGenderField !== null || (watchAgeField !== null && !isNaN(watchAgeField))) {
      setShowConsent(true);
    } else {
      setShowConsent(false);
      unregister('consent');
    }
  }, [watchGenderField, watchAgeField]);

  useEffect(() => {
    /** si l'utilisateur a plus de 15 ans / mettre pas de valeur d'age, show age consent */
    if (watchAgeField > 15 || watchAgeField === null || isNaN(watchAgeField)) {
      setShowAgeConsent(false);
      unregister('age_consent');
    } else {
      setShowAgeConsent(true);
    }
  }, [watchAgeField]);

  return (
    <form  id='user-form' onSubmit={handleSubmit(onSubmit)}>
      <div className="grid xs:grid-col-1 md:grid-col-10 md:gap-2">
        <div className="md:col-4">
          <div className="alert alert-primary flow">
            <div className="grid grid-col-3">
              <img src={process.env.PUBLIC_URL + '/image/streets-walking.svg'} alt="" />
            </div>
            <div className="heading">
                Merci de renseigner quelques informations complémentaires pour :
            </div>
            <ul className="checklist flow-half">
              <li>
                    Nous permettre de mieux analyser vos réponses
              </li>
              <li>
                    Vous envoyer par email un lien vous permettant de revenir à tout moment sur vos réponses
              </li>
              <li>
                    Être tenu·e informé·e des résultats de l’enquête
              </li>
            </ul>
            <p>Retrouvez <button className='btn-view-data' onClick={handleViewData}> ici </button> les informations concernant les données personnelles</p>
          </div>
        </div>
        <div className="md:col-6">
          <div className="survey-body flow">
            <FormInfoUser id='gender' role='radiogroup' label='Vous êtes' ariaLabel='gender'>
              <div className="flex gap-1">
                <div className="form-radio">
                  <input 
                    type="radio" 
                    name="gender" 
                    id="man" 
                    value="male" 
                    {...register('gender')}
                  />
                  <label htmlFor="man">Un homme</label>
                </div>
                <div className="form-radio">
                  <input 
                    type="radio" 
                    name="gender" 
                    id="woman" 
                    value="female" 
                    {...register('gender')}
                  />
                  <label htmlFor="woman">Une femme</label>
                </div>
                <div className="form-radio">
                  <input 
                    type="radio" 
                    name="gender" 
                    id="other" 
                    value="other" 
                    {...register('gender')}
                  />
                  <label htmlFor="other">Autre</label>
                </div>
              </div>
            </FormInfoUser>
            <div className="grid xs:grid-col-1 md:grid-col-1">
              <FormInfoUser id='age' ariaLabel='age' label='Votre âge'>
                <div className="form-input">
                  <input 
                    className="form-text" 
                    name="age" 
                    id="age" 
                    type='number'
                    min='10'
                    onKeyDown={(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                    {...register('age', {
                      valueAsNumber: true,
                      min: {
                        value: 11,
                        message: 'Vous devez avoir plus de 10 ans pour participer'
                      }},
                    )}
                  />
                  {errors.age && <p className='error-value'>{errors.age.message}</p>}                
                </div>
              </FormInfoUser>
              {/* <div className="md:col-3">
                <div className="form-group">
                  <FormInfoUser id='handicap' label='Avez-vous un handicap ? Si oui, précisez' ariaLabel='disability'>
                    <div className="form-input">
                      <Controller
                        name="handicap"
                        control={control}
                        render={() => (
                          <Select 
                            fullWidth
                            id="handicap" 
                            name='handicap' 
                            sx={{
                              fontSize: '1em',
                              borderRadius: '1.1rem',
                            }}
                            inputProps={{
                              sx: {
                                padding: '1.1rem',  
                                borderRadius: '1.1rem',
                              }
                            }}
                            displayEmpty
                            value={getValues('handicap')}
                            renderValue={(selected) => {
                              if (selected) {
                                return (
                                  <p>{listOptionHandicap.find(elem => elem.value === getValues('handicap')).label}</p>
                                );
                              } else {
                                return (
                                  <p>-- Choisissez une option --</p>
                                );
                              }
                            }}
                            {...register('handicap')} 
                          >
                            {listOptionHandicap.map(elem => (
                              <MenuItem key={elem.id} value={elem.value}>
                                <Checkbox sx={{fontSize: '1.5em'}} checked={elem.value === getValues('handicap')}/>
                                <p style={{fontSize: '1.5em'}}>{elem.label}</p>
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </div>
                  </FormInfoUser>              
                </div>
              </div> */}
            </div>
            <FormInfoUser id='email' ariaLabel='email' label='Votre email'>
              <div className="form-input">
                <input 
                  className="form-text" 
                  type="email" 
                  name="email" 
                  id="email"                
                  {...register('email', {
                    pattern: {
                      value:  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: 'Votre email ne correspond pas au format e-mail'
                    }
                  })}
                />
              </div>
              {errors.email && <p className='error-value'>{errors.email.message}</p>} 
            </FormInfoUser>
            {showConsent && (
              <FormInfoUser id='consent' >
                <div className="form-checkbox">
                  <input type="checkbox" name="consent" id="consent" {...register('consent')} />
                  <label htmlFor="consent">
                    Je consens à ce que les informations saisies soient utilisées dans le cadre de l’enquête
                  </label>
                </div>
              </FormInfoUser>
            )}
            {watchEmailField !== null && 
            watchEmailField !== '' && 
            watchEmailField.toLowerCase().match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
             && (
               <FormInfoUser id='email_consent' >
                 <div className="form-checkbox">
                   <input type="checkbox" name="email_consent" id="email_consent" {...register('email_consent')} />
                   <label htmlFor="email_consent">
                      Je consens à ce que mon adresse mail soit utilisée pour recevoir mon lien unique ainsi que pour recevoir les résultats de l’enquête
                   </label>
                 </div>
               </FormInfoUser>
             )}
            {/* {watchHandicapField !== null && watchHandicapField !== 'none' && (
              <FormInfoUser id='consent_handicap' >
                <div className="form-checkbox">
                  <input type="checkbox" name="consent_handicap" id="consent_handicap" {...register('consent_handicap')} />
                  <label htmlFor="consent_handicap">
                   Consent handicap
                  </label>
                </div>
              </FormInfoUser>
            )} */}
            {showAgeConsent && (
              <FormInfoUser id='age_consent' >
                <div className="form-checkbox">
                  <input type="checkbox" name="age_consent" id="age_consent" {...register('age_consent')} />
                  <label htmlFor="age_consent">
                    Les titulaires de l’autorité parentale consentent à ce que les informations saisies soient utilisées dans le cadre de l’enquête
                  </label>
                </div>
              </FormInfoUser>
            )}
            <UserFormFooter 
              modifyUserOnly={modifyOnlyUser}
              handleOnClickBackward={handleOnClickBackward} 
            />
          </div>
        </div>
      </div>
    </form> 

  );
};