/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import buffer from '@turf/buffer';
import bbox from '@turf/bbox';

import { GeocoderInput } from '../components/GeocoderInput';
import { SecondaryButton } from '../components/Button/SecondaryButton';
import { closeInfoPoi, resetPoi, setTypePoiSelected } from '../slices/poiSlice';
import { openSurvey } from '../slices/surveySlice';
import { hideOptionsList, openSearch, showOptionsList } from '../slices/searchSlice';
import { setAddStreet, startResetSelected } from '../slices/mapSlice';
import { StreetView } from '../components/StreetView';
import { Collapse, Skeleton } from '@mui/material';
import { closeNoti } from '../slices/notiSlice';
import { AlertNoti } from '../components/AlertNoti';

export const InfoPoiSelectedContainer = () => {

  const labelSelected = useSelector(state => state.poi.labelPoi);
  const typePoiSelected = useSelector(state => state.poi.typePoiSelected);
  const idView = useSelector(state => state.poi.idImageView);
  const loadingImage = useSelector(state => state.poi.loadingView);
  const notiShow = useSelector(state => state.noti.open);

  const dispatch = useDispatch();

  const [imageLoaded, setImageLoaded] = useState(false);

  const handlePoiSurvey = (e) => {
    e.preventDefault();
    dispatch(closeInfoPoi());
    dispatch(openSurvey());
  };

  const returnToSearchWithOutOptions = (e) => {
    e.preventDefault();
    dispatch(startResetSelected());
    dispatch(resetPoi());
    dispatch(hideOptionsList());
    dispatch(openSearch());
  };

  const returnToSearchWithOptions = (e) => {
    e.preventDefault();
    dispatch(startResetSelected());
    dispatch(closeInfoPoi());
    dispatch(showOptionsList());
    dispatch(openSearch());
  };


  const handleSetAllStreet = (e) => {
    e.preventDefault();
    dispatch(setAddStreet(true));
    dispatch(closeInfoPoi());
    dispatch(setTypePoiSelected('street'));
  };

  const onCloseNoti = () => {
    dispatch(closeNoti());
  };

  useEffect(() => {
    if (!loadingImage) {
      setImageLoaded(true);
    } else {
      setImageLoaded(false);
    }
  }, [loadingImage]);

  useEffect(() => {
    if (notiShow) {
      setTimeout(() => {
        dispatch(closeNoti());
      }, [3000]);
    }
  }, [notiShow]);

  const renderViewerImage = (viewId) => {
    if (viewId) {
      return (
        <StreetView idView={viewId} />
      );
    } else {
      return (
        <div className='no-view'>
          <img src={process.env.PUBLIC_URL + '/image/no-image-placeholder.svg'} alt="no-image" />
        </div> 
      );
    }
  };

  return (
    <div className="overlay flow">
      <Collapse in={notiShow} collapsedSize={0} mountOnEnter unmountOnExit>
        <AlertNoti closeCallback={onCloseNoti} />
      </Collapse>
      <div className="flex align-stretch gap-1">
        <button className="btn btn-empty btn-icon btn-icon-lg squeeze" onClick={returnToSearchWithOutOptions}>
          <span className="icon-caret-left"></span>
        </button>
        <div className="grow">
          <div className="form-group">
            <GeocoderInput 
              value={labelSelected}
              handlePressInput={returnToSearchWithOptions}
            />
          </div>
        </div>
      </div>
      <figure className="map-view square">
        {imageLoaded ? (
          <>
            {renderViewerImage(idView)}
          </>
        ) : (
          <Skeleton 
            className='loading-view'
            animation='wave'
            variant="rectangular"
          />
        )}
      </figure>
      <SecondaryButton label='Noter ce tronçon de rue/chemin' handleClick={handlePoiSurvey} />
      {typePoiSelected === 'portion' && (
        <SecondaryButton label='Noter toute la rue/chemin' handleClick={handleSetAllStreet} />
      )}
    </div>
  );
};